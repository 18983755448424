<template>
  <VueHtml2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="false"
    :preview-modal="false"
    :paginate-elements-by-height="1400"
    filename="myPDF"
    :pdf-quality="4"
    :manual-pagination="false"
    pdf-format="a7"
    pdf-orientation="portrait"
    pdf-content-width="260px"
    ref="html2Pdf"
    @beforeDownload="beforeDownload"
    :html-to-pdf-options="Html2PdfOptions"
  >
    <section
      slot="pdf-content"
      style="font-family: Arial, Helvetica, sans-serif"
    >
      <div class="outer-div">
        <div
          class="inner-div"
          style="margin-top: 20px"
        >
          <img
            src="/yassir-logo-new.png"
            height="50"
          />
          <div>YASSIR Express</div>
          <p style="font-size: 12px">
            Commande&nbsp;#{{ order.tracking_number_ext }}
          </p>
          <div
            style="
              font-size: 12px;
              text-align: center;
              padding: 0;
              margin-bottom: 10px;
            "
          >
            {{ order.created_at }}
          </div>
        </div>
      </div>
      <hr />

      <table
        v-if="order.products"
        style="
          font-size: 14px;
          justify-content: center;
          width: 100%;
          word-wrap: break-word;
        "
      >
        <thead>
          <tr style="text-align: center">
            <td style="width: 60%; text-align: left">Designation</td>
            <td style="width: 20%">Prix</td>
            <td style="width: 10%">Qte</td>
            <td style="width: 10%">Totale</td>
          </tr>
        </thead>

        <tbody style="border-top: 1px solid #e5e5e5 !important">
          <tr
            style="text-align: center"
            v-for="product in order.products"
            :key="product.id"
          >
            <td style="text-align: left">
              {{ product.name }}
              <span v-if="product?.promotion?.discount > 0"
                >({{ product.promotion.discount }}%)</span
              >
            </td>
            <td>{{ product.price }}</td>
            <td>{{ product.qte_in_order }}</td>
            <td style="padding-right: 10px">
              {{ product.total_item?.toFixed(2) }}
            </td>
          </tr>
        </tbody>
      </table>
      <hr style="border-top: 1px solid #e5e5e5 !important" />

      <table style="width: 100%; margin-top: 10px">
        <tbody style="">
          <tr style="vertical-align: top">
            <td align="left"></td>
            <td align="right"></td>
          </tr>
          <tr style="vertical-align: top; font-size: 14px; font-weight: none">
            <td
              style="border-top-color: rgb(232, 232, 232)"
              align="left"
            >
              TTC
            </td>
            <td
              style="border-top-color: rgb(232, 232, 232)"
              align="right"
            >
              <div style="width: 200px; padding-right: 10px">
                {{ order.ttc && order.ttc?.toFixed(2) }}&nbsp;DA
              </div>
            </td>
          </tr>
          <tr style="vertical-align: top; font-size: 14px; font-weight: none">
            <td
              style="border-top-color: rgb(232, 232, 232)"
              align="left"
            >
              Reduction
            </td>
            <td
              style="border-top-color: rgb(232, 232, 232)"
              align="right"
            >
              <div style="width: 200px; padding-right: 10px">
                {{ order.reduction }}&nbsp;DA
              </div>
            </td>
          </tr>
          <tr style="vertical-align: top; font-size: 14px; font-weight: none">
            <td
              style="border-top-color: rgb(232, 232, 232)"
              align="left"
            >
              Timbre
            </td>
            <td
              style="border-top-color: rgb(232, 232, 232)"
              align="right"
            >
              <div style="width: 200px; padding-right: 10px">
                {{ order.stamp_amount?.toFixed(2) }}&nbsp;DA
              </div>
            </td>
          </tr>
          <tr style="vertical-align: top; font-size: 12px; font-weight: 600">
            <td
              style="border-top-color: rgb(232, 232, 232)"
              align="left"
            >
              TOTAL
            </td>
            <td
              style="border-top-color: rgb(232, 232, 232)"
              align="right"
            >
              <div style="width: 200px; padding-right: 10px">
                {{ order.total?.toFixed(2) }}&nbsp;DA
              </div>
            </td>
          </tr>
          <template v-if="order.amount_received">
            <tr style="vertical-align: top; font-size: 14px; font-weight: none">
              <td
                style="border-top-color: rgb(232, 232, 232)"
                align="left"
              >
                Montant reçu
              </td>
              <td
                style="border-top-color: rgb(232, 232, 232)"
                align="right"
              >
                <div style="width: 200px; padding-right: 10px">
                  {{ order.amount_received?.toFixed(2) }}&nbsp;DA
                </div>
              </td>
            </tr>
            <tr style="vertical-align: top; font-size: 14px; font-weight: none">
              <td
                style="border-top-color: rgb(232, 232, 232)"
                align="left"
              >
                Montant retourné
              </td>
              <td
                style="border-top-color: rgb(232, 232, 232)"
                align="right"
              >
                <div style="width: 200px; padding-right: 10px">
                  {{ returnAmount?.toFixed(2) }}&nbsp;DA
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <hr />
      <div style="font-size: 12px; line-height: 9pt; justify-content: center">
        Le retour des produits sera accepté sous 48H sur présentation du ticket,
        il fera l'objet d'un avoir et nom d'un remboursement
      </div>

      <hr />
    </section>
  </VueHtml2pdf>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    VueHtml2pdf,
  },

  computed: {
    Html2PdfOptions: function () {
      return {
        enableLinks: false,
        filename: "myPDF.pdf",
        html2canvas: { scale: 2, useCORS: true },
        image: { type: "pdf", quality: 0.98 },
        jsPDF: {
          unit: "mm",
          format: [75, parseInt(100 + 5 * this.order?.products?.length)],
          orientation: "portrait",
        },
        margin: 3,
      };
    },
    returnAmount() {
      return this.order.amount_received - this.order.total;
    },
  },

  props: {
    order: {
      types: Object,
    },
  },

  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .outputPdf()
        .get("pdf")
        .then((pdfObj) => {
          const element = document.getElementById("invoice_iframe");
          element?.remove();
          var iframe = document.createElement("iframe");
          document.body.appendChild(iframe);
          iframe.style.display = "none";
          iframe.id = "invoice_iframe";
          iframe.src = pdfObj.output("bloburl");
          iframe.onload = function () {
            setTimeout(function () {
              iframe.focus();
              // iframe.contentWindow.print();
            }, 100);
          };

          const anchor = document.createElement("a");
          anchor.href = pdfObj.output("bloburl");
          anchor.download =
            "invoice_item_" + this.order.tracking_number_ext + ".pdf";
          anchor.target = "_blank";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        });
    },
  },

  data() {
    return {};
  },
};
</script>

<style>
.inner-div {
  text-align: center;
  margin: 0 auto;
  /* width: 100px; */
}
</style>
